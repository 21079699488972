import React from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet

function Header() {
  return (
    <div id='main'> 
      {/* SEO Meta Tags */}
      <Helmet>
        <title>Packers and Movers in Pakistan | Pak Loaders</title>
        <meta 
          name="description" 
          content="Pak Loaders offers reliable and affordable Packers and Movers services in Pakistan. Move your home with peace of mind. Contact us now!" 
        />
        <meta 
          name="keywords" 
          content="Packers and Movers Pakistan, Home Shifting Pakistan, Moving Services Pakistan, Reliable Packers Pakistan, Affordable Movers Pakistan" 
        />
        <meta name="robots" content="index, follow" />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Packers and Movers in Pakistan | Pak Loaders" />
        <meta 
          property="og:description" 
          content="Pak Loaders provides professional and affordable Packers and Movers services in Pakistan. Enjoy a hassle-free move with us." 
        />
        <meta property="og:url" content="https://pakloaders.com/multan-packers-movers" /> {/* Replace with the actual URL */}
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://pakloaders.com/images/header-image.jpg" /> {/* Replace with the actual image URL */}

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Packers and Movers in Pakistan | Pak Loaders" />
        <meta 
          name="twitter:description" 
          content="Reliable and professional Packers and Movers in Multan. Pak Loaders ensures your moving experience is stress-free." 
        />
        <meta name="twitter:image" content="https://pakloaders.com/images/header-image.jpg" /> {/* Replace with the actual image URL */}
      </Helmet>
      
      <div className='name'>
        <h2><span1>PAK LOADERS</span1></h2> {/* Adjusted inline style for better branding */}
        <h1>Move Your Home with Peace of Mind</h1>
        <p className='details'>
          Don't ask why you should hire us. Just ask yourself why you wouldn't.
        </p>
        <div className='header-btns'>
          <a href="tel:+923098791041" className='header-btn'>Call Us</a>
        </div>
      </div>
    </div>
  );
}

export default Header;
