import React from 'react';

function Featurebox(props) {
  return (
    <div className='a-box'>
      <div className='a-b-img'> {/* Fixed class name syntax */}
        <img 
          src={props.image} 
          alt={props.title || 'Feature Image'} 
        /> {/* Added descriptive alt text */}
      </div>
      <div className='a-b-text'>
        <h2>{props.title}</h2>
        <p>
          Pak Loaders offers Pakistan's largest and most reliable Packers and Movers network.
        </p>
      </div>
    </div>
  );
}

export default Featurebox;
