import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { collection, addDoc } from "firebase/firestore";
import { db } from './firebaseConfig';  // Import Firestore database instance

function Contact() {
  const [formData, setFormData] = useState({
    fullName: '',
    phoneNumber: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, 'inquiries'), {
        fullName: formData.fullName,
        phoneNumber: formData.phoneNumber,
        message: formData.message,
        timestamp: new Date()
      });
      alert('Inquiry submitted successfully!');
      setFormData({ fullName: '', phoneNumber: '', message: '' });  // Clear the form
    } catch (error) {
      console.error("Error adding document: ", error);
      alert('Failed to submit inquiry. Please try again.');
    }
  };

  return (
    <div id='contact'>
      {/* SEO Meta Tags */}
      <Helmet>
        <title>Contact Us | Pak Loaders - Packers and Movers in Pakistan</title>
        <meta 
          name="description" 
          content="Get in touch with Pak Loaders for all your packing and moving needs in Pakistan. Call us at +923098791041 or send us your inquiry." 
        />
        <meta 
          name="keywords" 
          content="Contact Pak Loaders, Packers and Movers Pakistan, Moving Services Contact, Call Pak Loaders, Inquiry Form" 
        />
        <meta name="robots" content="index, follow" />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Contact Us | Pak Loaders - Packers and Movers in Pakistan" />
        <meta 
          property="og:description" 
          content="Reach out to Pak Loaders for professional moving and packing services across Pakistan. We are here to assist you!" 
        />
        <meta property="og:image" content="https://pakloaders.com/contact-image.jpg" /> {/* Replace with actual image URL */}
        <meta property="og:url" content="https://pakloaders.com/contact" />
        <meta property="og:type" content="website" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Contact Us | Pak Loaders - Packers and Movers in Pakistan" />
        <meta 
          name="twitter:description" 
          content="Get in touch with Pak Loaders for seamless packing and moving services in Pakistan. Call us or submit an inquiry online." 
        />
        <meta name="twitter:image" content="https://pakloaders.com/contact-image.jpg" /> {/* Replace with actual image URL */}
      </Helmet>

      <h1>Contact Us</h1>
      <div className='header-btns'>
        <a href="tel:+923098791041" className='header-btn1'>+923098791041</a>
      </div>
      <form onSubmit={handleSubmit}>
        <input
          type='text'
          name='fullName'
          placeholder='Full Name'
          value={formData.fullName}
          onChange={handleChange}
          required
        />
        <input
          type='tel'
          name='phoneNumber'
          placeholder='Type Your Phone Number'
          value={formData.phoneNumber}
          onChange={handleChange}
          required
        />
        <textarea
          name='message'
          placeholder='Write Here....'
          value={formData.message}
          onChange={handleChange}
          required
        />
        <input type='submit' value='Send' />
      </form>
    </div>
  );
}

export default Contact;
