import React from "react";
import ReactDOM from "react-dom/client"; // Modern React import for rendering
import { HelmetProvider } from "react-helmet-async"; // Adding HelmetProvider for SEO
import "./index.css";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    {/* Wrapping the app with HelmetProvider for SEO support */}
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </React.StrictMode>
);
