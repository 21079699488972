import React from 'react';
import { Link } from 'react-scroll';
import { Helmet } from 'react-helmet'; // Import Helmet for SEO

function Offer() {
  return (
    <div id='offer'>
      {/* SEO Metadata */}
      <Helmet>
        <title>Effortless Packers and Movers Services | Pak Loaders</title>
        <meta name="description" content="Pak Loaders offers seamless moving solutions for homes and businesses in Pakistan. Any move, any time, anywhere. Get your free quote now!" />
        <meta name="keywords" content="Packers and Movers Pakistan, Home Moving, Business Relocation, Free Moving Quote, Reliable Packers" />
        <meta name="robots" content="index, follow" />
      </Helmet>

      <div className='pr-heading'>
        <h1><span>Pakistan's Leading Packers and Movers Platform</span></h1>
        <p className='details'>Effortless Moving Solutions for Homes and Businesses</p>
        <p className='details'>Any Move,</p>
        <p className='details'>Any Time,</p>
        <p className='details'>Anywhere</p>
        <div className='pr-btns'>
          {/* Smooth Scroll Button */}
          <Link
            to="contact" // The id of the target section (Contact)
            smooth={true} // Enable smooth scroll
            duration={500} // Scroll duration in milliseconds
            className='pr-btn' // Your CSS class for styling the button
          >
            GET A FREE QUOTE
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Offer;
