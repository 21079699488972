import React from "react";
import { Helmet } from "react-helmet-async"; // Updated to react-helmet-async for best practices
import Navbar from "./Components/Navbar";
import Header from "./Components/Header";
import Feature from "./Components/Feature";
import Offer from "./Components/Offer";
import About from "./Components/About";
import Contact from "./Components/Contact";

function App() {
  return (
    <div>
      {/* Helmet for SEO */}
      <Helmet>
        <title>Packers and Movers in Pakistan | Affordable Home Shifting | Pak Loaders</title>
        <meta
          name="description"
          content="Pak Loaders offers professional Packers and Movers services in Pakistan. Affordable home shifting, reliable moving services. Contact us for stress-free moves."
        />
        <meta
          name="keywords"
          content="Packers and Movers Pakistan, Home Shifting Pakistan, Moving services, Packers, Transport, Pak Loaders"
        />
        <meta name="robots" content="index, follow" />

        {/* Open Graph Meta Tags for Social Sharing */}
        <meta property="og:title" content="Packers and Movers in Pakistan | Affordable Home Shifting | Pak Loaders" />
        <meta
          property="og:description"
          content="Pak Loaders provides professional packing and moving services in Pakistan. Experience hassle-free relocation with our expert team."
        />
        <meta property="og:image" content="https://pakloaders.com/og-image.jpg" /> {/* Replace with your OG image URL */}
        <meta property="og:url" content="https://pakloaders.com" />
        <meta property="og:type" content="website" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Packers and Movers in Pakistan | Affordable Home Shifting | Pak Loaders" />
        <meta
          name="twitter:description"
          content="Affordable and reliable Packers and Movers services in Pakistan. Contact Pak Loaders for your moving needs."
        />
        <meta name="twitter:image" content="https://pakloaders.com/og-image.jpg" /> {/* Replace with your Twitter image URL */}

        {/* Canonical URL */}
        <link rel="canonical" href="https://pakloaders.com" />
      </Helmet>

      <div className="App">
        <Navbar />
        <Header />
        <Feature />
        <Offer />
        <About />
        <Contact />
      </div>

      {/* WhatsApp Button */}
      <a 
        href="https://wa.me/923098791041" 
        target="_blank" 
        className="whatsapp-btn" 
        rel="noopener noreferrer"
      >
        <img 
          src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" 
          alt="WhatsApp Button" 
        />
      </a>
    </div>
  );
}

export default App;
