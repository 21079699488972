import React, { useState, useEffect } from 'react';
import logo from '../images/logo1.png';
import { Link } from 'react-scroll';
import { Helmet } from 'react-helmet'; // Import Helmet for SEO

function Navbar() {
    const [nav, setnav] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false); // State for mobile menu visibility

    useEffect(() => {
        const changeBackground = () => {
            if (window.scrollY >= 50) {
                setnav(true);
            } else {
                setnav(false);
            }
        };

        window.addEventListener('scroll', changeBackground);
        return () => {
            window.removeEventListener('scroll', changeBackground);
        };
    }, []);

    // Toggle the menu state on mobile
    const handleMenuToggle = () => {
        setIsMenuOpen(prevState => !prevState);
    };

    return (
        <>
            <Helmet>
                <title>Pak Loaders | Best Packers and Movers in Pakistan</title>
                <meta name="description" content="Navigate through Pak Loaders' top services including Home Shifting, Office Moving, and Corporate Relocation. Reliable Packers and Movers in Pakistan." />
                <meta name="keywords" content="Packers and Movers, Home Shifting, Office Moving, Corporate Relocation, Pakistan Moving Services" />
                <meta name="robots" content="index, follow" />
            </Helmet>

            <nav className={nav ? "nav active" : "nav"}>
                <Link to='main' className='logo'>
                    <img src={logo} alt='Pak Loaders Logo' />
                </Link>

                {/* Hidden Checkbox for Menu Toggle */}
                <input
                    type="checkbox"
                    id="menu-btn"
                    className="menu-btn"
                    checked={isMenuOpen} // Tied to state
                    onChange={handleMenuToggle} // Toggling the menu state
                    style={{ display: 'none' }}
                />

                {/* Menu Icon */}
                <label className='menu-icon' htmlFor='menu-btn'>
                    <span className='nav-icon'></span>
                </label>

                {/* Menu Items */}
                <ul className={`menu ${isMenuOpen ? 'open' : ''}`}>
                    <li><Link to='main' smooth={true} duration={500}>Home</Link></li>
                    <li><Link to='features' smooth={true} duration={500}>Services</Link></li>
                    <li><Link to='contact' smooth={true} duration={500}>Get a Quote</Link></li>
                    <li><Link to='about' smooth={true} duration={500}>About us</Link></li>
                    <li><Link to='contact' smooth={true} duration={500}>Contact us</Link></li>
                </ul>
            </nav>
        </>
    );
}

export default Navbar;
