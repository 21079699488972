import React from 'react';
import { Helmet } from 'react-helmet-async';
import Featurebox from './Featurebox';

import fimage1 from '../images/11.jpg';
import fimage2 from '../images/22.jpg';
import fimage3 from '../images/33.jpg';
import fimage4 from '../images/44.jpg';

function Feature() {
  return (
    <div id='features'>
      {/* SEO Meta Tags */}
      <Helmet>
        <title>Our Services | Packers & Movers, Office & Residential Moving | Pak Loaders</title>
        <meta 
          name="description" 
          content="Explore our services: Packers and Movers, Office Moving, Corporate Relocation, and Residential Moving. Trusted solutions by Pak Loaders in Pakistan." 
        />
        <meta 
          name="keywords" 
          content="Packers and Movers Services, Office Moving Pakistan, Corporate Relocation, Residential Moving, Pak Loaders Services" 
        />
        <meta name="robots" content="index, follow" />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Our Services | Packers & Movers, Office & Residential Moving | Pak Loaders" />
        <meta 
          property="og:description" 
          content="Discover professional Packers and Movers services, office moving, corporate relocation, and residential moving solutions with Pak Loaders in Pakistan." 
        />
        <meta property="og:image" content="https://pakloaders.com/services-image.jpg" /> {/* Replace with actual image URL */}
        <meta property="og:url" content="https://pakloaders.com/services" />
        <meta property="og:type" content="website" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Our Services | Packers & Movers, Office & Residential Moving | Pak Loaders" />
        <meta 
          name="twitter:description" 
          content="Top-rated Packers and Movers services in Pakistan. We also offer office moving, corporate relocation, and residential moving services." 
        />
        <meta name="twitter:image" content="https://pakloaders.com/services-image.jpg" /> {/* Replace with actual image URL */}
      </Helmet>

      <h1>Services</h1>
      <div className='a-container'>
        <Featurebox image={fimage1} title="Packers & Movers" />
        <Featurebox image={fimage2} title="Office Moving" />
        <Featurebox image={fimage3} title="Corporate Relocation" />
        <Featurebox image={fimage4} title="Residential Moving" />
      </div>
    </div>
  );
}

export default Feature;
