import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import aboutimage from '../images/about.png';

function About() {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  const description = `Founded in October 2021, Pak Loaders is Pakistan's leading Packers and Movers platform, offering seamless and reliable moving solutions for residential, office, and corporate relocations. With an extensive transportation network across the country, Pak Loaders ensures hassle-free, timely, and professional moving services, designed to meet the diverse needs of our customers.`;

  const truncatedDescription = description.slice(0, 100); // Show first 100 characters

  return (
    <div id='about'>
      {/* SEO Meta Tags */}
      <Helmet>
        <title>About Us | Pak Loaders - Packers and Movers in Pakistan</title>
        <meta 
          name="description" 
          content="Learn more about Pak Loaders, Pakistan's trusted Packers and Movers platform. Offering professional moving solutions for homes, offices, and corporates since 2021." 
        />
        <meta 
          name="keywords" 
          content="About Pak Loaders, Packers and Movers Pakistan, Home Shifting, Office Relocations, Reliable Moving Services" 
        />
        <meta name="robots" content="index, follow" />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="About Us | Pak Loaders - Packers and Movers in Pakistan" />
        <meta 
          property="og:description" 
          content="Discover Pak Loaders, your trusted partner for seamless moving solutions in Pakistan. Serving residential and corporate clients with excellence since 2021." 
        />
        <meta property="og:image" content="https://pakloaders.com/about-image.jpg" /> {/* Replace with actual image URL */}
        <meta property="og:url" content="https://pakloaders.com/about" />
        <meta property="og:type" content="website" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="About Us | Pak Loaders - Packers and Movers in Pakistan" />
        <meta 
          name="twitter:description" 
          content="Get to know Pak Loaders, the leading Packers and Movers in Pakistan, providing reliable moving services since 2021." 
        />
        <meta name="twitter:image" content="https://pakloaders.com/about-image.jpg" /> {/* Replace with actual image URL */}
      </Helmet>

      <div className='about-image'>
        <img src={aboutimage} alt='About Pak Loaders' />
      </div>

      <div className='about-text'>
        <h1>Learn More About Us</h1>
        <p>
          {isExpanded ? description : truncatedDescription + '...'}
        </p>
        <button onClick={toggleReadMore} className='read-more-btn'>
          {isExpanded ? 'Show Less' : 'Read More'}
        </button>
      </div>
    </div>
  );
}

export default About;
